<template>
    <AdminWrapper>
        <template v-slot:child>
            <template v-if="$store.state.Admin.loadingState"><Spinner /></template>

            <!--Greeting, stats, overview -->
            <div class="w-90 center mt5 pt0-l pt4 f4 b">Activities</div>
            <div class="flex flex-wrap justify-between items-center w-90 center pv3">
                <div class="pb3 pb0-l">
                    <p>
                        Here is what is going on
                    </p>
                </div>
            </div>
            <!--Greeting, stats, overview -->

            <!-- filter and search -->
            <div class="w-90 center pt3">
                <div class="pv3 flex flex-wrap justify-between items-center">
                    <div class="w-25-l f5 mb0-l mb3" style="color:#132C8C;">
                        <SearchInput 
                            placeholder="Search businesses..." 
                            v-model="state.searchVal" 
                            @update:modelValue="search" 
                        />
                    </div>
                    <div class="flex flex-wrap items-end justify-between w-60-l w-100">
                        <div class="pl3-l mb0-l mb3 w-third-l w-50">
                            <div class="pb1 font-w1">Start date</div>
                            <input type="date" name="" class="input-style w-100" v-model="start" />
                        </div>
                        <div class="ph3-l mb0-l mb3 w-third-l w-50  pl3">
                            <div class="pb1 font-w1">End date</div>
                            <input type="date" name="" class="input-style w-100" v-model="end" @change="onChangeDate(end)" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- filter and search -->

            <!-- Attributes-->
            <div class="scrollmenu w-90 center pt2">
                <table class="w-100 tl pt3 bt b--black-20" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 bb b--black-20 pr3 tl lh-title">
                                Business
                            </th>
                            <th class="pb3 bb b--black-20 pr3 tl lh-title">
                                Type
                            </th>
                            <th class="pb3 bb b--black-20 pr3 tl lh-title">
                                Message
                            </th>
                            <th class="pb3 bb b--black-20 pr3 tl lh-title">
                                No. of Events
                            </th>
                            <th class="pb3 bb b--black-20 pr3 tl lh-title">
                                Status
                            </th>
                            <th class="pb3 bb b--black-20 pr3 tl lh-title">
                                Created
                            </th>
                            <!-- <th class="pb3 bb b--black-20 pr3 tl lh-title">
                                Action
                            </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="font-w1" v-for="event in allBusinessesActivities.data" :key="event._id">
                            <td class="pv3 pr3 bb b--black-20 tl makeBold">
                                <router-link :to="{ name: 'AdminAccountDetails', params: { id: event.org } }">{{
                                    event?.orgName
                                }}</router-link>
                            </td>
                            <td class="pv3 pr3 bb b--black-20 tl makeBoldx">
                                <!-- <div :id="'template-'+event._id" class="hidden">
                                    <div class="max-w-md w-full">
                                        <div class="m-2">
                                            <div class="text-black font-bold text-xl">
                                                Can coffee make you a better developer?
                                            </div>
                                            <p class="text-grey-darker text-base">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                                Voluptatibus quia, nulla! Maiores et perferendis eaque,
                                                exercitationem praesentium nihil.
                                            </p>
                                        </div>
                                    </div>
                                </div> -->
                                <div v-tippy="{ html: `#template-${event._id}`, interactive : true, theme : 'light' }">{{ event?.type }}</div>
                            </td>
                            <td class="pv3 pr3 bb b--black-20 tl">{{ event.description }}</td>
                            <td class="pv3 pr3 bb b--black-20 tl">{{ event.metaData ? `${event.metaData.length} actions` : '' }}</td>
                            <td class="pv3 pr3 bb b--black-20 tl">
                                <span v-if="event.status === 'successful'" class="badge badge-success">{{ event.status }}</span>
                                <span v-if="event.status === 'failed'" class="badge badge-danger">{{ event.status }}</span>
                            </td>
                            <td class="pv3 pr3 bb b--black-20 tl">
                                {{ formatDate(event.createdAt, "D/MM/Y HH:mm A") }}
                            </td>
                            <!-- <td class="pv3 pr3 bb b--black-20 tl">
                                <button 
                                    v-if="event.status === 'failed'"
                                    class="pv1"
                                    @click="revertAction(event._id)"
                                >Revert</button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <Pagination
                :limit="$route.query.limit"
                :pages="allBusinessesActivities?.pagination"
                route-name="AdminAccount"
                :onNavigate="onNavigate"
            /> -->
           
            <div class="scrollmenu w-90 center pt2">
                <Page
                    v-model="allBusinessesActivities.page"
                    :total-row="allBusinessesActivities?.total"
                    @change="onPageChange"
                    language="en"
                />
            </div>
        </template>
    </AdminWrapper>
</template>

<script>
import { reactive, ref } from 'vue'
import Swal from 'sweetalert2'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { sortByNumericData, sortAlphaNumericData, sortByDate, formatDateFilter, formatDate } from '@/utils/lib'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import moment from 'moment-timezone'
import { computed, onMounted } from '@vue/runtime-core'
import Spinner from '@/components/Spinner'
import Pagination from '@/components/Pagination'
import SearchInput from 'vue-search-input'
import { Page } from 'v-page'
export default {
    components: {
        AdminWrapper,
        Spinner,
        Pagination,
        SearchInput,
        Page,
    },
    setup() {
        const route = useRoute()
        const store = useStore()
        const partners = computed(() => store.state.Admin.partners)
        const business = computed(() => store.state.Admin.business)
        const allBusinessesActivities = computed(() => store.state.Admin.allBusinessesActivities)
        const businessPicked = ref('')
        const getNameOnload = ref()
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        const start = ref(formatDateFilter('2000-01-01'))
        const end = ref(formatDateFilter(new Date()))
        const showAttribute = ref(false)
        const isOpen = ref(false)
        const currentBusinessID = ref('')
        // table toggles
        const bizName = ref(true)
        const phoneNo = ref(false)
        const bizOwnerName = ref(true)
        const bizOwnerEmail = ref(true)
        const noOfUsers = ref(true)
        const plans = ref(false)
        const createdAt = ref(false)
        const lastActivity = ref(false)
        const typeBiz = ref(true)
        const bizRole = ref(false)
        const bizEmail = ref(true)
        const bizSource = ref(false)
        const bizModel = ref(true)
        const bizPartner = ref(false)
        const currentPage = ref(1);

        const state = reactive({
            searchVal: '',
        })

        const toggleAttributeDropDown = () => {
            showAttribute.value = !showAttribute.value
        }

        const toggleTableAttribute = name => {
            if (name === 'bizName') {
                bizName.value = !bizName.value
            }

            if (name === 'phoneNo') {
                phoneNo.value = !phoneNo.value
            }

            if (name === 'bizOwnerName') {
                bizOwnerName.value = !bizOwnerName
            }

            if (name === 'bizOwnerEmail') {
                bizOwnerEmail.value = !bizOwnerEmail
            }

            if (name === 'noOfUsers') {
                noOfUsers.value = !noOfUsers.value
            }

            // if (name === 'plans') {
            //     plans.value = !plans.value
            // }

            if (name === 'createdAt') {
                createdAt.value = !createdAt.value
            }

            if (name === 'lastActivity') {
                lastActivity.value = !lastActivity.value
            }

            if (name === 'typeBiz') {
                typeBiz.value = !typeBiz.value
            }

            if (name === 'bizRole') {
                bizRole.value = !bizRole.value
            }

            if (name === 'bizEmail') {
                bizEmail.value = !bizEmail.value
            }

            if (name === 'bizModel') {
                bizModel.value = !bizModel.value
            }

            if (name === 'bizSource') {
                bizSource.value = !bizModel.value
            }

            if (name === 'bizPartner') {
                bizPartner.value = !bizPartner.value
            }
        }

        const onChange = e => {
            // console.log('get', e)
            if (e === 'all') {
                const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }

                store.dispatch('Admin/getAccountAllBusinessData', payload)
            } else {
                const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }
                store.dispatch('Admin/getPartnerBusiness', { id: e, payload })
            }
            currentBusinessID.value = e
        }

        const search = async () => {
            if (state.searchVal !== '' && state.searchVal.length < 3) {
                return;
            }
            await store.dispatch('Admin/getPartners')
            checkifAll(true)
        }

        onMounted(async () => {
            const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }
            store.dispatch('Admin/getAllActivities', payload)
        })

        const onChangeDate = () => {
            if (businessPicked.value.text === undefined) {
                const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }

                store.dispatch('Admin/getAccountAllBusinessData', payload)
            } else {
                const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }
                store.dispatch('Admin/getPartnerBusiness', { id: currentBusinessID.value, payload: payload })
            }
        }

        const revertAction = (id) => {
            Swal.fire({
                    title: `Revert Action`,
                    text: `Are you sure you want to revert this action(s)?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Proceed!',
                    denyButtonText: `No, Cancel!`,
                }).then((result) => {
                    if (result.isConfirmed) {

                    }
                })
        }

        const onNavigate = query => {

            const payload = {
                // page: route.query.page,
                limit: query,
                filterType: 'adminRange',
                startDate: start.value,
                endDate: end.value
            }
            // console.log('payload', payload)
            store.dispatch('Admin/getAccountAllBusinessData', query)
        }

        const onPageChange = page => {
            const query = {
                limit: page?.pageSize || 25,
                page: page?.pageNumber || 1,
                filterType: 'adminRange',
                startDate: start.value,
                endDate: end.value
            }
            store.dispatch('Admin/getAccountAllBusinessData', query)
        }

        const sortPartnerTable = (order, value) => {
            const query = {
                data: business.value,
                order: order,
                value: value
            }

            switch (value) {
                case 'noOfUsers':
                    sortByNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'name':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizSource':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizModel':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizOwnerName':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'typeBiz':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizOwnerEmail':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'createdAt':
                    sortByDate(query)
                    isOpen.value = !isOpen.value
                    break
                case 'lastActivity':
                    sortByDate(query)
                    isOpen.value = !isOpen.value
                    break
                // case 'plans':
                //     sortByDate(query)
                //     isOpen.value = !isOpen.value
                //     break
                case 'typeBiz':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizRole':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value

                    break
                default:
                    break
            }
        }

        const checkifAll = (isSearch=false) => {
            const payload = {
                page: route.query.page || 1,
                limit: route.query.limit || 25,
                filterType: 'adminRange',
                start: start.value,
                end: end.value
            }

            if (isSearch) {
                payload.q = state.searchVal
            }

            if (store.state.Admin.adminUserData.userType === 'admin') {
                store.dispatch('Admin/getAccountAllBusinessData', payload)
                businessPicked.value = 'all'
            } else {
                const partner = store.state.Admin.adminUserData.partner;
                businessPicked.value = { id: partner?._id, text: partner?.name };
                currentBusinessID.value = partner?._id
                store.dispatch('Admin/getPartnerBusiness', { id: partner?._id, payload })
            }
        }

        const initializeYearlyInventoryReport = () => {
            const today = new Date();
            const startDate = moment(today).format('YYYY-MM-DD');
            const currentYear = today.getFullYear()
            const endDate = `${currentYear}-12-31`;
            store.dispatch('Admin/initializeYearlyInventoryReport', {startDate, endDate})
        }

        return {
            state,
            sortPartnerTable,
            businessPicked,
            getNameOnload,
            onChange,
            partners,
            end,
            start,
            showAttribute,
            toggleAttributeDropDown,
            toggleTableAttribute,
            bizName,
            phoneNo,
            bizOwnerName,
            bizOwnerEmail,
            noOfUsers,
            plans,
            createdAt,
            lastActivity,
            business,
            allBusinessesActivities,
            isOpen,
            year,
            month,
            today,
            onChangeDate,
            currentBusinessID,
            typeBiz,
            bizRole,
            onNavigate,
            onPageChange,
            checkifAll,
            bizEmail,
            bizSource,
            bizModel,
            bizPartner,
            formatDate,
            search,
            initializeYearlyInventoryReport,
            currentPage,
            revertAction,
        }
    }
}
</script>

<style>
.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.makeAbsolute {
    position: absolute;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: 1;
    width: 80%;
}

.makeBBottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding: 8px 4px;
    display: flex;
    /* justify-content: center; */
}

.makeBBottom:hover {
    background: rgb(240, 241, 242);
}

.makeBold {
    font-weight: 700;
}
/* .pagination-container {
    display: flex;
    column-gap: 10px;
  }
  .paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
  }
  .paginate-buttons:hover {
    background-color: #d8d8d8;
  }
  .active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
  }
  .active-page:hover {
    background-color: #2988c8;
  } */
</style>
